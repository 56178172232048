import useInputRfc from './useInputRfc';
import useInputRut from './useInputRut';

const countryInputHooks = {
  Chile: useInputRut,
  Mexico: useInputRfc,
};

const useInputNationalIdentifier = (country) => {
  const InputHook = countryInputHooks[country || 'Chile'];
  return InputHook;
};

export default useInputNationalIdentifier;
