import { useState } from 'react';
import { validateRfc } from '../helpers/forms';

const useInputRfc = (initialValue) => {
  const [rfc, setRfc] = useState(initialValue || '');

  const updateRfc = (newRfc) => {
    const updatedRfc = newRfc.toUpperCase().replace(/ /g, '');
    setRfc(updatedRfc || '');
  };

  return {
    updateNationalIdentifier: updateRfc,
    isValid: validateRfc(rfc),
    nationalIdentifier: {
      formatted: rfc,
      raw: rfc,
    },
    validationType: 'rfc',
  };
};

export default useInputRfc;
